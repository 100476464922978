import { cva as _cva, cx } from "class-variance-authority"

export const cva = _cva as <T>(
  base?: ClassValue,
  config?: Config<T>,
) => (props?: Props<T>) => string

// TODO: replace with `export const cx = (...classes: ClassValue[]) => classes.flat().filter(Boolean).join(" ")`
//       once we have migrated all cx off object args
export { cx }

// #region Types

export type ClassValue =
  | string
  | number
  | boolean
  | ClassArray
  // TODO: remove this once we have migrated all cx off object args
  | ClassDictionary
  | null
  | undefined

export type VariantProps<Component> = Component extends (props?: infer T) => string
  ? // TODO: remove "class" once we have migrated apps from "class-variant-authority" to "@iyk/ui"
    Omit<T, "class" | "className">
  : never

// #endregion

// #region Internal cx types

// TODO: remove this once we have migrated all cx off object args
type ClassDictionary = Record<string, any>

// TODO: move this into `ClassValue` once we have migrated all cx off object args
type ClassArray = ClassValue[]

// #endregion

// #region Internal cva types

type ConfigSchema = Record<string, Record<string, ClassValue>>

type Config<T> = T extends ConfigSchema
  ? {
      variants?: T
      defaultVariants?: ConfigVariants<T>
      compoundVariants?: (T extends ConfigSchema
        ? (ConfigVariants<T> | ConfigVariantsMulti<T>) & ClassProp
        : ClassProp)[]
    }
  : never

type ConfigVariants<T extends ConfigSchema> = {
  [Variant in keyof T]?: StringToBoolean<keyof T[Variant]>
}

type ConfigVariantsMulti<T extends ConfigSchema> = {
  [Variant in keyof T]?:
    | StringToBoolean<keyof T[Variant]>
    | StringToBoolean<keyof T[Variant]>[]
}
type StringToBoolean<T> = T extends "true" | "false" ? boolean : T

type ClassProp = {
  className?: ClassValue
}

type Props<T> = T extends ConfigSchema ? ConfigVariants<T> & ClassProp : ClassProp

// #endregion
